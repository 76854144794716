<template>
  <q-layout view="lHh Lpr lFf" class="l-print">
    <q-header
      v-if="route.name != 'Login' && route.name != 'Outgoing Faktur'"
      unelevated
      class="bg-white text-black"
    >
      <q-toolbar>
        <q-img
          :src="`./img/logo_head.png`"
          height="40px"
          width="115px"
          fit="contain"
        ></q-img>

        <q-space></q-space>
        <div class="row items-center q-gutter-x-sm">
          <div class="text-grey-6">v 2.2.1</div>
          <q-avatar>
            <img :src="'./img/avatar.png'" />
          </q-avatar>
          <q-btn-dropdown
            flat
            no-caps
            dropdown-icon="keyboard_arrow_down"
            dense
          >
            <template v-slot:label>
              <div class="text-left">
                <div class="text-bold">{{ route.meta.user?.name }}</div>
                <div class="l-text-thin text-grey-6" style="line-height: 14px">
                  {{ route.meta.user?.role }}
                </div>
              </div>
            </template>

            <q-card class="row no-wrap all-radius column" style="width: 250px">
              <q-card-section class="column items-center q-gutter-y-sm">
                <q-avatar size="72px">
                  <img :src="'./img/avatar.png'" />
                </q-avatar>
                <div class="text-center">
                  <div class="text-bold l-text-detail">
                    {{ route.meta.user?.name }}
                  </div>
                  <div
                    class="l-text-thin text-grey-6"
                    style="line-height: 14px"
                  >
                    {{ route.meta.user?.role }}
                  </div>
                </div>
              </q-card-section>

              <q-btn
                dense
                flat
                class="q-px-md q-py-sm"
                icon="lock_outline"
                label="Ubah Sandi"
                no-caps
                align="left"
                v-close-popup
                @click="showDialogPass"
              />
              <q-separator size="1px" color="grey-4"></q-separator>
              <q-btn
                dense
                flat
                class="q-px-md q-py-sm"
                icon="logout"
                label="Logout"
                color="negative"
                no-caps
                align="left"
                v-close-popup
                @click="logout"
              />
            </q-card>
          </q-btn-dropdown>
        </div>
      </q-toolbar>
      <q-separator />
    </q-header>

    <q-drawer
      v-if="
        route.name != 'Login' &&
        !isMobile &&
        is_drawer_shown &&
        route.name != 'Outgoing Faktur'
      "
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-white column"
      :mini="miniState"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
    >
      <q-list>
        <q-item
          clickable
          class="inactive-menu"
          :active-class="miniState ? 'text-brand' : 'text-brand active-menu'"
          to="/"
        >
          <q-item-section avatar>
            <q-icon name="dashboard" />
          </q-item-section>

          <q-item-section>Dashboard</q-item-section>
        </q-item>

        <q-item
          clickable
          class="inactive-menu"
          :active-class="miniState ? 'text-brand' : 'text-brand active-menu'"
          to="/report"
        >
          <q-item-section avatar>
            <q-icon name="summarize" />
          </q-item-section>

          <q-item-section>Laporan Cetak Resi</q-item-section>
        </q-item>

        <q-expansion-item
          class="cursor-pointer"
          :class="
            route.name == 'List' ||
            route.name == 'Outbound' ||
            route.name == 'Manual via Excell' ||
            route.name == 'Barang Retur' ||
            route.name == 'another'
              ? `text-brand`
              : ''
          "
          group="menuGroup"
          :model-value="
            is_expanded([
              'List',
              'Outbound',
              'Manual via Excell',
              'Barang Retur',
              'another',
            ])
          "
        >
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon name="barcode_reader" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Scan Resi</q-item-label>
            </q-item-section>
          </template>
          <q-item
            active-class="active-menu"
            :active="route.name == 'List'"
            to="/scan"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="route.name == 'List' ? 'text-brand' : 'text-black'"
              >List</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Outbound'"
            to="/scan/detail"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="route.name == 'Outbound' ? 'text-brand' : 'text-black'"
              >Scan</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Retur Keluar'"
            to="/scan/retur"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Retur Keluar' ? 'text-brand' : 'text-black'
              "
              >Retur</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Manual vie Excell'"
            to="/scan/manual"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Manual vie Excell' ? 'text-brand' : 'text-black'
              "
              >Input via Excell</q-item-section
            >
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          class="cursor-pointer"
          :class="
            route.name == 'List Retur' ||
            route.name == 'Form Retur' ||
            route.name == 'Barang Retur Masuk' ||
            route.name == 'Barang Retur Keluar' ||
            route.name == 'Proses Barang Retur' ||
            route.name == 'Atur Pengiriman Barang Retur'
              ? `text-brand`
              : ''
          "
          group="menuGroup"
          :model-value="
            is_expanded([
              'List Retur',
              'Form Retur',
              'Barang Retur Masuk',
              'Barang Retur Keluar',
              'Proses Barang Retur',
              'Atur Pengiriman Barang Retur',
            ])
          "
        >
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon name="o_assignment_return" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Barang Retur</q-item-label>
            </q-item-section>
          </template>
          <q-item
            active-class="active-menu"
            :active="route.name == 'List Retur'"
            to="/retur"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="route.name == 'List Retur' ? 'text-brand' : 'text-black'"
              >List</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Form Retur'"
            to="/retur/form"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="route.name == 'Form Retur' ? 'text-brand' : 'text-black'"
              >Form</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Barang Retur Masuk'"
            to="/retur/in"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Barang Retur Masuk' ? 'text-brand' : 'text-black'
              "
              >Scan Retur Masuk</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Barang Retur Keluar'"
            to="/retur/out"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Barang Retur Keluar'
                  ? 'text-brand'
                  : 'text-black'
              "
              >Scan Retur Keluar</q-item-section
            >
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          class="cursor-pointer"
          :class="
            route.name == 'Loan List' || route.name == 'Loan Detail'
              ? `text-brand`
              : ''
          "
          :model-value="is_expanded(['Loan List', 'Loan Detail'])"
          group="menuGroup"
        >
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon name="handshake" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Shoes on Loan</q-item-label>
            </q-item-section>
          </template>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Loan List'"
            to="/loan"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="route.name == 'Loan List' ? 'text-brand' : 'text-black'"
              >List</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Loan Detail'"
            to="/loan/detail"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="route.name == 'Loan Detail' ? 'text-brand' : 'text-black'"
              >Scan</q-item-section
            >
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          class="cursor-pointer"
          :class="
            route.name == 'Outgoing List' || route.name == 'Outgoing Form'
              ? `text-brand`
              : ''
          "
          :model-value="is_expanded(['Outgoing List', 'Outgoing Form'])"
          group="menuGroup"
        >
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon name="outbox" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Sepatu Keluar</q-item-label>
            </q-item-section>
          </template>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Outgoing List'"
            to="/outgoing"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Outgoing List' ? 'text-brand' : 'text-black'
              "
              >List</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Outgoing Form'"
            to="/outgoing/form"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Outgoing Form' ? 'text-brand' : 'text-black'
              "
              >Form</q-item-section
            >
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          class="cursor-pointer"
          :class="
            route.name == 'Inbound List' || route.name == 'Inbound Detail'
              ? `text-brand`
              : ''
          "
          group="menuGroup"
          :model-value="is_expanded(['Inbound List', 'Inbound Detail'])"
        >
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon name="local_shipping" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Barang Masuk</q-item-label>
            </q-item-section>
          </template>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Inbound List'"
            to="/inbound"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Inbound List' ? 'text-brand' : 'text-black'
              "
              >List</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Inbound Detail'"
            to="/inbound/detail"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Inbound Detail' ? 'text-brand' : 'text-black'
              "
              >Form</q-item-section
            >
          </q-item>
        </q-expansion-item>

        <q-expansion-item
          class="cursor-pointer"
          :class="
            route.name == 'List Stok' ||
            route.name == 'Riwayat Stok' ||
            route.name == 'Pengingat Stok' ||
            route.name == 'Riwayat Penyesuaian Stok'
              ? `text-brand`
              : ''
          "
          group="menuGroup"
          :model-value="
            is_expanded([
              'List Stok',
              'Riwayat Stok',
              'Pengingat Stok',
              'Riwayat Penyesuaian Stok',
            ])
          "
        >
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon name="inventory" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Stok Barang</q-item-label>
            </q-item-section>
          </template>
          <q-item
            active-class="active-menu"
            :active="route.name == 'List Stok'"
            to="/stock"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="route.name == 'List Stok' ? 'text-brand' : 'text-black'"
              >List Stok</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Riwayat Penyesuaian Stok'"
            to="/stock/adjustment"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Riwayat Penyesuaian Stok'
                  ? 'text-brand'
                  : 'text-black'
              "
              >Riwayat Penyesuaian</q-item-section
            >
          </q-item>
          <q-item
            active-class="active-menu"
            :active="route.name == 'Pengingat Stok'"
            to="/stock/reminder"
          >
            <q-item-section avatar></q-item-section>
            <q-item-section
              :class="
                route.name == 'Pengingat Stok' ? 'text-brand' : 'text-black'
              "
              >Pengingat Stok</q-item-section
            >
          </q-item>
        </q-expansion-item>

        <q-item
          clickable
          class="inactive-menu"
          :active-class="miniState ? 'text-brand' : 'text-brand active-menu'"
          to="/sinkronisasi"
        >
          <q-item-section avatar>
            <q-icon name="sync" />
          </q-item-section>

          <q-item-section>Sinkronisasi</q-item-section>
        </q-item>

        <q-item
          clickable
          class="inactive-menu"
          :active-class="miniState ? 'text-brand' : 'text-brand active-menu'"
          to="/analisa"
        >
          <q-item-section avatar>
            <q-icon name="analytics" />
          </q-item-section>

          <q-item-section>Analisa</q-item-section>
        </q-item>

        <q-item clickable active-class="text-brand" @click="showDialogExport">
          <q-item-section avatar>
            <q-icon name="summarize" />
          </q-item-section>

          <q-item-section>Export Code-Color</q-item-section>
        </q-item>

        <q-item
          clickable
          class="inactive-menu"
          :active-class="miniState ? 'text-brand' : 'text-brand active-menu'"
          to="/settings"
        >
          <q-item-section avatar>
            <q-icon name="settings" />
          </q-item-section>

          <q-item-section>Settings</q-item-section>
        </q-item>

        <q-item
          clickable
          class="inactive-menu"
          :active-class="miniState ? 'text-brand' : 'text-brand active-menu'"
          to="/integrasi"
        >
          <q-item-section avatar>
            <q-icon name="connect_without_contact" />
          </q-item-section>

          <q-item-section>Integrasi E-Commerce</q-item-section>
        </q-item>
      </q-list>
      <q-space></q-space>
    </q-drawer>

    <q-page-container class="my-font printCond">
      <router-view class="my-font l-mobile-container l-print" />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import useBasic from "./composables/useBasic";
import dialogExportExcell from "./components/dialogExportExcell.vue";
import dialogExcellColumn from "./components/dialogExcellColumn.vue";
import dialogPassword from "./components/dialogPassword.vue";
export default {
  name: "LayoutDefault",

  setup() {
    const { route, router, $q, reactive, onMounted, isMobile, watch } =
      useBasic();

    onMounted(async () => {
      // showDialogColumn();
    });

    let showDialogPass = () => {
      $q.dialog({
        component: dialogPassword,
      });
    };

    let routes = ref([
      { label: "", desc: "", link: "", is_expanded: false, icon: "" },
    ]);

    let data = reactive({
      router_to: "",
    });

    let logout = () => {
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      router.push("/login");
    };

    let showDialogExport = () => {
      $q.dialog({
        component: dialogExportExcell,
      });
    };

    let showDialogColumn = () => {
      $q.dialog({
        component: dialogExcellColumn,
      });
    };

    let miniState = ref(true);

    let drawerClick = (e) => {
      if (miniState.value) {
        miniState.value = false;

        e.stopPropagation();
      }
    };

    let is_expanded = (menus) => {
      if (menus.includes(route.name)) return true;
      return false;
    };

    let is_drawer_shown = ref(true);

    return {
      leftDrawerOpen: ref(false),
      route,
      router,
      data,
      logout,
      showDialogExport,
      drawerClick,
      miniState,
      isMobile,
      showDialogPass,
      is_expanded,
      is_drawer_shown,
    };
  },
};
</script>
<style lang="scss">
@import "@/styles/quasar.scss";
.stickyTable {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $grey-2;
    color: #000000;
    font-weight: bold;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}

.stickyHead_LastCol {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $grey-2;
    color: #000000;
    font-weight: bold;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }

  /* Sticky for the last column */
  tbody td:last-child,
  thead th:last-child {
    position: sticky;
    right: 0;
    background-color: $grey-1;
    z-index: 2; /* Higher z-index to ensure it's above other content */
  }
}

.l-container {
  height: 100vh;
}

.q-drawer {
  margin-top: 50px !important;
}

.q-header {
  left: 0 !important;
}

.l-mobile-container {
  max-width: 1440px;
  min-width: 300px;

  margin: 0 auto;
}

.l-footer-container {
  max-width: 1400px;
  min-width: 300px;

  margin: 0 auto;
}

.l-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-grow {
  flex-grow: 99 !important;
}

@font-face {
  font-family: barcode;
  src: url(./styles/fonts/barcode.TTF);
}

@font-face {
  font-family: code128;
  src: url(./styles/fonts/code128.ttf);
}

@font-face {
  font-family: customfont;
  src: url(./styles/fonts/Sora-Regular.ttf);
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Sora-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Sora-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Sora-Bold.ttf");
  font-weight: 300;
}

.my-font {
  font-family: "customfont";
}

.barcode-font {
  font-family: "barcode" !important;
}

.barcode-128 {
  font-family: "code128" !important;
}

.f10 {
  font-size: 10pt;
}
.f12 {
  font-size: 12pt;
}
.f14 {
  font-size: 14pt;
}
.f16 {
  font-size: 16pt;
}
.f18 {
  font-size: 18pt;
}
.f20 {
  font-size: 20pt;
}

.text-bolder {
  font-weight: 900;
}

.bg-brand {
  background: #3d3099 !important;
}

.text-brand {
  color: #3d3099 !important;
}

.l-text-header {
  font-size: 40px !important;
  line-height: 1.8;
  @media only screen and (max-width: 900px) {
    font-size: 36px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px !important;
  }
}

.l-text-title {
  font-size: 32px !important;
  line-height: 1.8;
  @media only screen and (max-width: 900px) {
    font-size: 26px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 20px !important;
  }
}

.l-text-subtitle {
  font-size: 20px !important;
  line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 16px !important;
  }
}

.l-text-detail {
  font-size: 18px !important;
  line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 13px !important;
  }
}

.l-text-description {
  font-size: 14px !important;
  line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 11px !important;
  }
}

.border-card {
  border-radius: 10px !important;
  border: 1px solid #bbbbbb;
}

.long-text-dots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.kiri-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 0 0 8px !important;
}

.kanan-radius {
  border: 1px solid #bbbbbb;
  border-radius: 0 8px 8px 0 !important;
}
.atas-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 8px 0 0 !important;
}
.bawah-radius {
  border: 1px solid #bbbbbb;
  border-radius: 0 0 8px 8px !important;
}

.all-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 8px 8px 8px !important;
}

.active-menu {
  padding-left: 14px !important;
  border-left: 2px solid #3d3099 !important;
}

.inactive-menu {
  border-left: none;
}

.l-wrap-cell {
  word-wrap: break-word !important; /* Ensures that words break and wrap to the next line */
  white-space: normal !important; /* Overrides any contrary settings that prevent wrapping */
  word-break: break-all;
}

.printPaper {
  width: 148mm;
}

.printCond {
  background-color: $grey-3 !important; /* Remove any background */
}

@media print {
  .printCond {
    background-color: white !important; /* Remove any background */
    margin: 0;
    padding: 0;
  }
  .l-print {
    min-height: none !important;
  }
  .q-layout {
    min-height: 0 !important;
  }
  .q-page {
    min-height: 0 !important;
  }
}
</style>
